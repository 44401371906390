// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-tsx": () => import("/opt/build/repo/src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-templates-post-tsx": () => import("/opt/build/repo/src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-page-tsx": () => import("/opt/build/repo/src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-blog-tsx": () => import("/opt/build/repo/src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-contacts-tsx": () => import("/opt/build/repo/src/templates/contacts.tsx" /* webpackChunkName: "component---src-templates-contacts-tsx" */),
  "component---src-templates-home-tsx": () => import("/opt/build/repo/src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-privacy-tsx": () => import("/opt/build/repo/src/templates/privacy.tsx" /* webpackChunkName: "component---src-templates-privacy-tsx" */),
  "component---src-templates-projects-tsx": () => import("/opt/build/repo/src/templates/projects.tsx" /* webpackChunkName: "component---src-templates-projects-tsx" */),
  "component---src-templates-services-tsx": () => import("/opt/build/repo/src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

